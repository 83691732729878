import '../../MapMenu.scss';
import { capitalize } from '@utils';
import { getFormattedDateTime } from '@utils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Icon, Text } from '../../../../components';
import { V2FilterMapList } from '../../../AssetFilter/functions/V2FilterMapList';

export function MapEditList({
  data,
  type,
  listData,
  fetchData,
  visible,
  setClickedItem,
  setViewItem,
  setSelected,
  setIsCreating,
}) {
  const [title, setTitle] = useState<string>(null);
  const [sort, setSort] = useState<any>({ field: 'Name', ascending: true });
  const [search, setSearch] = useState<string>('');
  const [andOr, setAndOr] = useState<string>('and');
  const [assetFilters, setAssetFilters] = useState<any>([]);
  const [listItems, setListItems] = useState<any>([]);
  const history = useHistory();

  // Merge map items and existing items into one list for color coding
  const getListItems = (listFeatureCol, mapFeatureCol) => {
    // Saved items
    let listIds = listFeatureCol.features.map((feature) => {
      return {
        Id: feature.properties.Id,
        Name: feature.properties.Name,
        LastCheckIn: feature.properties.LastCheckIn,
        EntityType: feature.properties.EntityType,
      };
    });
    // Map Items
    let mapIds = mapFeatureCol.features.map((feature) => {
      return {
        Id: feature.properties.Id,
        Name: feature.properties.Name,
        LastCheckIn: feature.properties.LastCheckIn,
        EntityType: feature.properties.EntityType,
      };
    });
    // Compare saved vs map items to get new and deleted items
    const deletedItems = listIds.filter((item) => !mapIds.map((x) => x.Id).includes(item.Id));
    const newItems = mapIds.filter((item) => !listIds.map((x) => x.Id).includes(item.Id));
    const existingItems = listIds.filter((item) => mapIds.map((x) => x.Id).includes(item.Id));
    // Add color code
    deletedItems.forEach((item) => {
      item.color = 0;
    });
    newItems.forEach((item) => {
      item.color = 1;
    });
    existingItems.forEach((item) => {
      item.color = 2;
    });
    // Combine arrays
    const allItems = [...newItems, ...deletedItems, ...existingItems];
    return allItems;
  };

  const debounceFetchData = useCallback(
    debounce((assetFilters, andOr, sort, search, type) => {
      fetchData(V2FilterMapList(assetFilters, andOr, sort, search), type);
    }, 500),
    [],
  );

  // Create list
  useEffect(() => {
    if (data && listData) {
      setListItems(getListItems(listData, data.toGeoJSON()));
    }
  }, [data, listData, type]);

  useEffect(() => {
    if (type) {
      type === 'stationary' ? setTitle('Stationary Assets') : setTitle(capitalize(type) + 's');
    } else {
      setTitle(null);
    }
  }, [type]);

  useEffect(() => {
    debounceFetchData(assetFilters, andOr, sort, search, type);
  }, [type, assetFilters, andOr, sort, search]);

  //Handle asset filter data changes
  // const handleFilterChange = (e, type) => {
  //   if (JSON.stringify(e) !== JSON.stringify(assetFilters)) {
  //     setAssetFilters(e);
  //   }
  //   if (type !== andOr) {
  //     setAndOr(type);
  //   }
  // };

  return (
    <div className={visible ? 'object-wrapper-edit' : 'object-wrapper-none'}>
      <div className={visible ? 'pinned-controls-edit' : 'pinned-controls-none'}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <Text className="title-text fw-bold fs-md me-3">{title ? title : ''}</Text>
          <Icon
            name={'add'}
            title="Close"
            className="sonarIcon-rotate-45 align-sub nav-text-icon"
            variant="light"
            disabled={false}
            size="md"
            onClick={() => {
              setSelected(null);
            }}
          />
        </div>
        {type !== 'overlay' && (
          <div className="d-flex flex-row align-items-center justify-content-between"></div>
        )}
        <div className="d-flex flex-row align-items-center justify-content-between title-row mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <Text className="title-text fw-bold fs-md me-2">Create New</Text>
            <Icon
              name={'add'}
              title={`Add ${capitalize(type)}`}
              className="align-sub primary-icon"
              variant="light"
              disabled={false}
              size="md"
              onClick={() => {
                setIsCreating(true);
              }}
            />
          </div>
        </div>
      </div>
      {listItems && (
        <div className="list-container-edit">
          {listItems?.map((listItem, index) => {
            return (
              <div id={listItem.Id} key={index}>
                <div
                  className={
                    listItem.color === 0
                      ? 'deleted-item'
                      : listItem.color === 1
                      ? 'new-item'
                      : 'list-item'
                  }
                >
                  <div className="list-info">
                    <div className="name-col">{listItem.Name}</div>
                    {listItem.LastCheckIn && (
                      <div className="checkin-col">
                        {getFormattedDateTime(listItem.LastCheckIn)}
                      </div>
                    )}
                  </div>
                  <div className="btns-col">
                    {(type === 'asset' || type === 'stationary') && (
                      <Button
                        title="Go to Asset"
                        className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                        variant="light"
                        disabled={listItem.color === 0 || listItem.color === 1}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          history.push('/assets/' + listItem.Id);
                          e.stopPropagation();
                        }}
                      >
                        <i className={`sonarIcon sonarIcon-open-in fs-md`} />
                      </Button>
                    )}
                    <Button
                      title="Show more"
                      className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
                      variant="light"
                      disabled={listItem.color === 0 || listItem.color === 1}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        let newData = Object.values(data._layers);
                        setViewItem({ properties: listItem });
                        setClickedItem(
                          newData.find((item) => {
                            return (item as any).feature.properties.Id.toString() === listItem.Id;
                          }),
                        );
                        e.stopPropagation();
                      }}
                    >
                      <i className={`sonarIcon sonarIcon-h-dots-1 fs-md`} />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
