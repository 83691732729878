// Variable Data Constants
import { store } from '../redux';

export const STORAGE_KEYS = {
  APP_OBJECTS: 'APP_OBJECTS', // a stringified array of app objects [{ id, name }]
};

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
};

export const TRIGGER_TYPES = {
  Battery: 0,
  Temperature: 1,
  Humidity: 2,
  //Movement,
  //PlaceChange = 4,
  //ProximityToLocation,
  //ProximityToGateway
};

export const CONVERSIONS = {
  FtoC: (f) => ((f - 32) * (5 / 9)).toFixed(1),
  CtoF: (c) => (c * (9 / 5) + 32).toFixed(1),
  PaToPsi: (pa) => (pa / 6894.7572931783).toFixed(2),
};

export const ABILITY_MAP = {
  Temperature: {
    id: 3,
    name: 'Temperature',
    unit: () => {
      const { temp } = store.getState().userPrefs;
      return temp === ('Fahrenheit' || 'F') ? '\u00B0F' : '\u00B0C';
    },
    units: [
      {
        id: 'c',
        value: 'c',
        text: 'C',
      },
      {
        id: 'f',
        value: 'f',
        text: 'F',
      },
    ],
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      const { temp } = store.getState().userPrefs;
      const unit = temp === ('Fahrenheit' || 'F') ? '\u2109' : '\u2103';
      const value = temp === ('Fahrenheit' || 'F') ? CONVERSIONS.CtoF(val) : val; // backend values stored in C so if user prefs choose F, do conversion
      return parseFloat(value).toFixed(1) + unit;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      const { temp } = store.getState().userPrefs;
      const value = temp === ('Fahrenheit' || 'F') ? CONVERSIONS.CtoF(val) : val;
      return Number(value);
    },
  },
  Battery: {
    id: 1,
    name: 'Battery',
    unit: () => '%',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(0)}%`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val).toFixed(0);
    },
  },
  Location: {
    id: 2,
    name: 'Location',
    unit: () => '',
    value: (val) => val,
    numValue: (val) => Number(val).toFixed(2),
  },
  Humidity: {
    id: 4,
    name: 'Humidity',
    unit: () => '%',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(1)}%`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Vibration: {
    id: 6,
    name: 'Vibration',
    unit: () => 'g',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(4)} g`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  'Barometric Pressure': {
    id: 5,
    name: 'Barometric Pressure',
    unit: () => 'psi',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      const parsedVal = Number(val).toFixed(2);
      return `${CONVERSIONS.PaToPsi(parsedVal)} psi`;
    },
    // numValue: (val) => Number(val).toFixed(2)
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      const value = CONVERSIONS.PaToPsi(val);
      return Number(value);
    },
  },
  Pressure: {
    id: -1,
    name: 'Pressure',
    unit: () => {
      const { bar } = store.getState().userPrefs;
      return bar === 'kPa' ? 'kPa' : 'psi';
    },
    units: [
      {
        id: 'kpa',
        value: 'kpa',
        text: 'kPa',
      },
      {
        id: 'psi',
        value: 'psi',
        text: 'psi',
      },
    ],
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      const kpa = val / 1000;
      const parsedKpa = Number(kpa).toFixed(2);
      const parsedVal = Number(val).toFixed(2);
      return `${CONVERSIONS.PaToPsi(parsedVal)} psi (${parsedKpa} kPa)`;
    },
    // numValue: (val) => Number(val).toFixed(2)
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      const value = CONVERSIONS.PaToPsi(val);
      return Number(value);
    },
  },
  'Gateway Health Check': {
    id: 22,
    name: 'Gateway Health Check',
    unit: () => 'pings',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.n;
      }
      return `${Number(val).toFixed(2)} pings`;
    },
    numValue: (val) => {
      if (typeof val === 'object') {
        val = val.n;
      }
      return Number(val);
    },
  },
  'Health Check': {
    id: 21,
    name: 'Health Check',
    unit: () => 'pings',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.n;
      }
      return `${Number(val).toFixed(2)} pings`;
    },
    numValue: (val) => {
      if (typeof val === 'object') {
        val = val.n;
      }
      return Number(val).toFixed(0);
    },
  },
  Orientation: {
    id: 24,
    name: 'Orientation',
    unit: () => 'rad',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} radians`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Voltage: {
    id: 8,
    name: 'Voltage',
    unit: () => 'volts',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} volts`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Amperage: {
    id: 8,
    name: 'Amperage',
    unit: () => 'amps',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} amps`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Frequency: {
    id: 7,
    name: 'Frequency',
    unit: () => 'Hz',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} Hz`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Power: {
    id: -2,
    name: 'Power',
    unit: () => 'watts',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} watts`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  'Power Consumption': {
    id: -3,
    name: 'Power Consumption',
    unit: () => 'kWh',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} kWh`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  'Power Factor': {
    id: -3,
    name: 'Power Factor',
    unit: () => '%',
    value: (val) => {
      if (typeof val === 'object') {
        val = val.mean;
      }
      return `${Number(val).toFixed(2)} %`;
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
  Generic: {
    id: 0,
    name: 'Generic',
    unit: () => '',
    value: (val) => {
      let textOnly = false;
      if (typeof val === 'object') {
        textOnly = val.textOnly;
        val = val.mean;
      }
      if (isNaN(Number(val)) || Number(val) === undefined || textOnly) {
        return val;
      } else {
        return `${Number(val).toFixed(2)}`;
      }
    },
    numValue: (val, dataOption) => {
      if (typeof val === 'object') {
        val = val[dataOption];
      }
      return Number(val);
    },
  },
};

export const ABILITIES = Object.values(ABILITY_MAP).map((ability) => ability.name);
export const IGNORED_ABILITIES = ['Health Check', 'Light', 'Gateway Health Check'];

export const ONLINE_STATUS_MAP = {
  0: {
    status: 'Offline',
    variant: 'danger',
  },
  1: {
    status: 'Online',
    variant: 'success',
  },
  2: {
    status: 'Other',
    variant: 'warning',
  },
  3: {
    status: 'No Beacons',
    variant: 'secondary',
  },
};
