import './MapList.scss';
import { Api } from '@services';
import { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  EditCellRenderer,
  LinkCellRenderer,
  MapThumbnailCellRenderer,
  Icon,
  Text,
} from '../../../components';
import { PagedQueryParams, ServerSideGrid } from '../../../components/grids/AgGrid/ServerSideGrid';
import { PagedModel } from '../../../models/PagedModel';
import { mapListColumnDefs } from './columnDefs';

export function MapList() {
  const [rowData, setRowData] = useState<any>(new PagedModel());
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(true); // Track grid visibility
  const csvFileName = 'Maps_Export';

  const FetchData = async (payload: any, isCSV: any, queryState: any) => {
    setIsLoading(true);

    Api.get(
      `maps?top=${queryState.pageSize}&skip=${queryState.skip()}&filter=${queryState.filter}`,
    ).then((results: any) => {
      const isUserSearching = queryState.filter && queryState.filter.length > 0;

      // Hide grid only if NO maps exist at all (not just search results)
      if (!isUserSearching && results.data.count === 0) {
        setShowGrid(false);
      } else {
        setShowGrid(true);
      }

      setRowData(results.data);
      setIsLoading(false);
    });

    // Help create call to export Assets
    if (isCSV) {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      };

      Api.get(`maps/export?search=${queryState.filter}`, config)
        .then((res) => {
          setCsvData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Card className="map-list-card h-100">
      <Card.Body>
        {showGrid ? (
          <ServerSideGrid
            id={'MapListGrid'}
            columnDefs={mapListColumnDefs}
            handleFetchData={FetchData}
            rowData={rowData}
            csvData={csvData}
            csvFileName={csvFileName}
            isLoading={isLoading}
            disabledFilters={[]}
            filterControl={false}
            searchControl={true}
            viewingControl={true}
            pagerControl={true}
            exportControl={true}
            gridProps={{
              frameworkComponents: {
                EditCellRenderer,
                LinkCellRenderer,
                MapThumbnailCellRenderer,
              },
              rowHeight: 80,
              suppressClickEdit: true,
              editType: 'fullRow',
              enableBrowserTooltips: false,
              tooltipShowDelay: 0,
              tooltipMouseTrack: true,
            }}
            initQueryParams={new PagedQueryParams()}
          />
        ) : (
          <div className="map-list-empty">
            <Icon
              name="map"
              variant="dark"
              size="8x"
              className="mb-3"
              disabled={false}
              showPointer={false}
            />
            <Text className="mb-3" as="h5">
              Whoops looks like you don't have any maps!
            </Text>
            <Text className="mb-4" as="h5">
              Would you like to create one?
            </Text>
            <Link to="/maps/create">
              <Button className="create-map" variant="primary">
                Create Map
                <i className="sonarIcon sonarIcon-add fs-sm" />
              </Button>
            </Link>
            <Link to="/maps">
              <Button className="mt-2 ps-5 pe-5" variant="secondary">
                Back
              </Button>
            </Link>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
