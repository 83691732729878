import '../../screens/assets/AssetScreen.scss';
import './AdvancedAsset.scss';
import '../../scss/comps/Swal.scss';
import { Icon, Text } from '@components';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from '../../components';
import { ABILITY_MAP } from '../../constants';
import { useApp } from '../../react';
import AssetScreen from '../../screens/assets/AssetScreen';
import DeleteModal from '../../screens/assets/DeleteModal';
import { Api, favoriteService } from '../../services';
import { chartColors, chartLines } from './ChartColors';
import ChartFilters from './ChartFilters';
import PlotlyChart from './PlotlyChart';

const AdvancedCharting = ({ lastReadings, assetName, asset, beacons }) => {
  const cancelToken = Api.CancelToken;
  const source = cancelToken.source();
  const app = useApp();

  const [chartKey, setChartKey] = useState(0);
  const [addRunChartKey, setAddRunChartKey] = useState(0);
  const [updateDataAsset, setUpdateDataAsset] = useState({});
  const [isFavorite, setIsFavorite] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addRun, setAddRun] = useState(undefined);
  const [normalize, setNormalize] = useState(false);
  const [traces, setTraces] = useState([]);
  const [addTraces, setAddTraces] = useState([]);
  const [graphStartDate, setGraphStartDate] = useState(null);
  const [graphEndDate, setGraphEndDate] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [formattedAddRunStartDate, setFormattedAddRunStartDate] = useState(null);
  const [formattedAddRunEndDate, setFormattedAddRunEndDate] = useState(null);
  const [formattedGraphEndDate, setFormattedGraphEndDate] = useState(null);
  const [addRunEndDate, setAddRunEndDate] = useState(null);
  const [interSelectedRange, setInterSelectedRange] = useState(null);
  const [secInterSelectedRange, setSecInterSelectedRange] = useState(null);
  const [chartBeacons, setChartBeacons] = useState(
    lastReadings.map((beacon, index) => {
      return { name: beacon.name, id: beacon.id, selected: false };
    }),
  );
  const readingLabels = ['Max', 'Min', 'Mean', 'Median', 'Mode', 'Range', 'Std. Dev'];
  const selectedBeacons = chartBeacons.filter((beacon) => beacon.selected);
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const toggleCards = () => {
    setShowCards(!showCards);
  };

  useEffect(() => {
    checkIsFavorite();
  }, []);

  useEffect(() => {
    if (graphEndDate) {
      setFormattedGraphEndDate(moment(graphEndDate).format('MM/DD/YYYY h:mm A'));
    }
    setChartKey(chartKey + 1);
  }, [graphEndDate]);

  // Effect for updating formattedAddRunStartDate when addRun changes
  useEffect(() => {
    if (addRun) {
      setFormattedAddRunStartDate(moment(addRun).format('MM/DD/YYYY h:mm A'));
    }
    setAddRunChartKey(addRunChartKey + 1);
  }, [addRun]);

  // New date range handle when interacting with 1st graph
  const handleRangeUpdate = (newRange) => {
    setInterSelectedRange(newRange);
  };
  // New date range handle when interacting with 2nd graph
  const addRunHandleRangeUpdate = (newRange) => {
    setSecInterSelectedRange(newRange);
  };

  // When user interacts with range of 1st plotly graph will update date range displayed & data
  useEffect(() => {
    if (interSelectedRange) {
      setGraphStartDate(moment(interSelectedRange[0]));
      setFormattedGraphEndDate(moment(interSelectedRange[1]).format('MM/DD/YYYY h:mm A'));
    }
  }, [interSelectedRange]);

  // When user interacts with range of 2nd plotly graph will update date range displayed & data
  useEffect(() => {
    if (secInterSelectedRange) {
      setFormattedAddRunStartDate(moment(secInterSelectedRange[0]).format('MM/DD/YYYY h:mm A'));
      setFormattedAddRunEndDate(moment(secInterSelectedRange[1]).format('MM/DD/YYYY h:mm A'));
    }
  }, [secInterSelectedRange]);

  // Get temperature preference from Redux store
  const tempPreference = useSelector((state) => state.userPrefs.temp);

  // #region FAVORITE
  const favoriteAsset = async () => {
    await favoriteService.favoriteAsset(asset.id);
    checkIsFavorite();
  };

  const unfavoriteAsset = async () => {
    await favoriteService.unfavoriteAsset(asset.id);
    checkIsFavorite();
  };

  const checkIsFavorite = async () => {
    const { data } = await favoriteService.getFavorites(app.id);
    const isFavorite = data.find(
      (fav) => fav.itemType === 'Asset' && fav.itemId === Number(asset.id),
    );
    setIsFavorite(!!isFavorite);
  };
  // #endregion FAVORITE

  //#region Delete Asset
  const deleteAsset = async () => {
    if (beacons.length) {
      setIsDeleteModalOpen(true);
    } else {
      let userResponsePromise = new Promise((resolve, reject) => {
        MySwal.fire({
          title: 'Are you sure you want to delete this asset?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
          .then((submit) => {
            resolve(submit.isConfirmed);
          })
          .catch((err) => {
            reject(err);
          });
      });

      let userResponse;

      try {
        userResponse = await userResponsePromise;
      } catch (error) {
        userResponse = false;
        console.log(error);
      }

      if (userResponse) {
        const { assetId } = this.props.match.params;
        try {
          await Api.delete(`/assets/${assetId}`, {
            cancelToken: source.token,
          });
          history.push('/assets');
          toast.success('Asset was successfully deleted!');
        } catch (error) {
          toast.error('Failed to delete the asset.');
        }
      }
    }
  };

  //#endregion Delete Asset

  //#region Edit Asset Name
  const setAssetData = (newAsset) => {
    setUpdateDataAsset((prevAsset) => ({
      ...prevAsset,
      ...newAsset,
    }));

    updateAsset();
  };
  //#endregion Edit Asset Name

  //#region update Asset Name
  const updateAsset = debounce(async () => {
    const arrayOfIdentifiers = asset.identifiers.map((item) => [item.key, item.value]);
    const updatedAsset = {
      name: asset.name,
      keywords: asset.keywords,
      movementThreshold: asset.movementThreshold,
      identifiers: arrayOfIdentifiers,
    };

    try {
      await Api.put(`/assets/${asset.id}`, updatedAsset, {
        cancelToken: source.token,
      });
      toast.success('Successfully updated asset!');
    } catch (err) {
      console.log(err);
      toast.error(`Failed to update the asset.\n${err}.`);
    }
  }, 200);

  //#region

  const [chartAbilities, setChartAbilities] = useState(
    lastReadings
      .map((beacon) => beacon.readings)
      .reduce((acc, current) => acc.concat(current), [])
      .map((reading, index) => {
        return { name: reading.ability, id: reading.abilityId, selected: false };
      })
      .filter((value, index, self) => index === self.findIndex((a) => a.id === value.id)),
  );
  const [chartHours, setChartHours] = useState([
    { name: 1, id: 1, selected: false },
    { name: 2, id: 2, selected: false },
    { name: 4, id: 4, selected: false },
    { name: 8, id: 8, selected: false },
    { name: 12, id: 12, selected: false },
  ]);

  const getData = async (url) => {
    const data = await Api.get(url, { cancelToken: source.token }).then(({ data }) => {
      return data;
    });
    return data;
  };

  const getTraces = async (selectedBeaconAbility, main = true) => {
    const promiseList = selectedBeaconAbility
      .map((beacon) => {
        return beacon.abilities.map((ability) => {
          return getData(ability.url);
        });
      })
      .reduce((acc, current) => acc.concat(current), []);

    await Promise.all(promiseList).then((results) => {
      const apiData = results.map((result, index) => {
        const ability = selectedBeaconAbility
          .map((beacon) => beacon.abilities)
          .reduce((acc, current) => acc.concat(current), [])[index];
        const abilityFormatter = ABILITY_MAP[ability.name] || ABILITY_MAP['Generic'];

        return {
          x: result.map((reading) => new Date(reading.timestamp)),
          y: result.map((reading) => abilityFormatter.numValue(reading, 'mean')),
        };
      });

      const traceData = selectedBeaconAbility
        .map((beacon, bindex) => {
          return beacon.abilities.map((ability, aindex) => {
            const apiElement = apiData.shift();
            return {
              name: `${beacon.name} - ${ability.name}`,
              x: apiElement.x,
              y: apiElement.y,
              line: {
                color: chartColors[bindex],
                dash: chartLines[aindex],
                width: 3,
              },
              mode: 'lines',
              opacity: 1,
            };
          });
        })
        .reduce((acc, current) => acc.concat(current), [])
        .filter((data) => !!data.x.length);
      if (main) {
        setTraces(traceData);
      } else {
        setAddTraces(traceData);
      }
    });
  };

  const getBeaconAbilityMap = (
    selectedBeacons,
    selectedAbilities,
    graphStartDate,
    graphEndDate,
  ) => {
    let newStartDate = new Date(graphStartDate).toISOString();
    let newEndDate = new Date(graphEndDate).toISOString();
    const selectedBeaconAbility = selectedBeacons.map((beacon) => {
      return {
        id: beacon.id,
        name: beacon.name,
        abilities: selectedAbilities.map((ability) => {
          const url = `/beacons/${beacon.id}/readings/${ability.id}?start=${newStartDate}&end=${newEndDate}`;
          return {
            ...ability,
            url: url,
          };
        }),
      };
    });
    return selectedBeaconAbility;
  };

  const addHours = (numHours, date = new Date()) => {
    const dateCopy = new Date(date.getTime());
    dateCopy.setTime(dateCopy.getTime() + numHours * 60 * 60 * 1000);
    return dateCopy;
  };

  useEffect(() => {
    const selectedBeacons = chartBeacons
      .filter((beacon) => beacon.selected)
      .map((beacon) => beacon);
    const selectedAbilities = chartAbilities
      .filter((ability) => ability.selected)
      .map((ability) => ability);
    const selectedBeaconAbility = getBeaconAbilityMap(
      selectedBeacons,
      selectedAbilities,
      graphStartDate,
      graphEndDate,
    );
    const isSelected = chartHours.some((hour) => hour.selected);
    if (isSelected) {
      getTraces(selectedBeaconAbility);
    }
  }, [chartBeacons, chartAbilities, graphEndDate, graphStartDate, tempPreference]);

  useEffect(() => {
    const selectedBeacons = chartBeacons
      .filter((beacon) => beacon.selected)
      .map((beacon) => beacon);
    const selectedAbilities = chartAbilities
      .filter((ability) => ability.selected)
      .map((ability) => ability);
    const selectedHours = chartHours.filter((hour) => hour.selected).map((hour) => hour.name);
    const isSelected = chartHours.some((hour) => hour.selected);
    if (!!addRun && isSelected) {
      const dt = new Date(addRun);
      const startDate = dt;
      const endDate = addHours(selectedHours[0], dt);
      setFormattedAddRunEndDate(moment(endDate).format('MM/DD/YYYY h:mm A'));
      setAddRunEndDate(endDate);
      const selectedBeaconAbility = getBeaconAbilityMap(
        selectedBeacons,
        selectedAbilities,
        startDate.toISOString(),
        endDate.toISOString(),
      );
      if (isSelected) {
        getTraces(selectedBeaconAbility, false);
      }
    }
  }, [addRun, chartBeacons, chartAbilities, chartHours, tempPreference]);

  const handleNormalizeCheck = (e) => {
    setNormalize(e.currentTarget.checked);
  };

  const handleAddRunChange = (e) => {
    setAddRun(e);
  };

  const handleDropdownSelect = (dropdown, data) => {
    switch (dropdown) {
      case 'Beacons':
        setChartBeacons(data);
        break;
      case 'Abilities':
        setChartAbilities(data);
        break;
      case 'Hours':
        setChartHours(data);
        break;
    }
  };

  // CALCULATES Stats for data selected determined for label and date selected data
  function calculateStats(label, numbersArray) {
    if (!Array.isArray(numbersArray) || numbersArray.length === 0) {
      throw new Error('Input must be a non-empty array.');
    }

    const formatNumber = (number) => {
      number = parseFloat(number);
      if (isNaN(number)) return 0;

      // Avoid scientific notation for very small numbers close to zero
      if (Math.abs(number) < 1e-7) {
        return number.toFixed(2);
      }

      // Use scientific notation if the number is very large or very small
      if (Math.abs(number) >= 1e7 || Math.abs(number) <= 1e-7) {
        return number.toExponential(2); // Convert to scientific notation with 2 decimal places
      }
      return +number.toFixed(2); // Fixed-point notation with 2 decimal places
    };

    switch (label) {
      case 'Max':
        return formatNumber(Math.max(...numbersArray));
      case 'Min':
        return formatNumber(Math.min(...numbersArray));
      case 'Mean':
        const mean =
          numbersArray.reduce((sum, num) => sum + parseFloat(num), 0) / numbersArray.length;
        return formatNumber(mean);
      case 'Median':
        const sortedArray = numbersArray.slice().sort((a, b) => parseFloat(a) - parseFloat(b));
        const middleIndex = Math.floor(sortedArray.length / 2);
        if (sortedArray.length % 2 === 0) {
          return formatNumber(
            (parseFloat(sortedArray[middleIndex - 1]) + parseFloat(sortedArray[middleIndex])) / 2,
          );
        } else {
          return formatNumber(parseFloat(sortedArray[middleIndex]));
        }
      case 'Mode':
        const countMap = new Map();
        numbersArray.forEach((num) => {
          num = parseFloat(num);
          countMap.set(num, (countMap.get(num) || 0) + 1);
        });
        let maxCount = 0;
        let mode;
        for (const [num, count] of countMap.entries()) {
          if (count > maxCount) {
            maxCount = count;
            mode = num;
          }
        }
        return formatNumber(mode);
      case 'Range':
        const range =
          Math.max(...numbersArray.map((num) => parseFloat(num))) -
          Math.min(...numbersArray.map((num) => parseFloat(num)));
        return formatNumber(range);
      case 'Std. Dev':
        const meanValue =
          numbersArray.reduce((sum, num) => sum + parseFloat(num), 0) / numbersArray.length;
        const squaredDifferences = numbersArray.map((num) =>
          Math.pow(parseFloat(num) - meanValue, 2),
        );
        const variance =
          squaredDifferences.reduce((sum, squaredDiff) => sum + squaredDiff, 0) /
          numbersArray.length;
        const standardDeviation = Math.sqrt(variance);
        return formatNumber(standardDeviation);
      default:
        throw new Error(
          'Invalid label. Supported labels: Max, Min, Mean, Median, Mode, Range, Std. Dev',
        );
    }
  }

  return (
    <div className="advanced-asset-all-cards-wrapper">
      <div className="d-flex">
        <Card className="advanced-asset-main-card">
          <Card.Header>
            <Row>
              <DeleteModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                asset={asset}
                beacons={beacons}
              />
              <AssetScreen
                pageType={'advancedAsset'}
                asset={asset}
                setAsset={setAssetData}
                deleteAsset={deleteAsset}
                isFavorite={isFavorite}
                favoriteAsset={favoriteAsset}
                unfavoriteAsset={unfavoriteAsset}
                updateAsset={updateAsset}
              />
            </Row>
            {/* Beacon, Ability, Hours, Normalize, ect */}
            <Row className="mb-3">
              <ChartFilters
                title={assetName}
                chartBeacons={chartBeacons}
                chartAbilities={chartAbilities}
                chartHours={chartHours}
                normalize={normalize}
                addRun={addRun}
                addRunEndDate={addRunEndDate}
                handleDropdownSelect={handleDropdownSelect}
                handleNormalizeCheck={handleNormalizeCheck}
                handleAddRunChange={handleAddRunChange}
                setGraphStartDate={setGraphStartDate}
                setGraphEndDate={setGraphEndDate}
                graphEndDate={graphEndDate}
              />
            </Row>
          </Card.Header>
          <Card.Body>
            <Row className="advanced-asset-row mb-1">
              <Col>
                {traces.length === 0 ? (
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <Icon
                      name="analytics"
                      size="6x"
                      className="mb-3"
                      variant="dark"
                      disabled={false}
                      showPointer={false}
                    />
                    {chartHours.some((item) => item.selected === true) && traces.length === 0 ? (
                      <>
                        <Text className="mb-4" as="h5">
                          There are currently no Readings for the selected Beacon/Ability/Time
                          combination.
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text className="mb-3" as="h5">
                          To Generate Graph:
                        </Text>
                        <Text className="mb-4" as="h5">
                          Please Select Beacon, Ability, Start Date, & Hours.
                        </Text>
                      </>
                    )}
                  </div>
                ) : (
                  <PlotlyChart
                    key={chartKey}
                    traces={traces}
                    title={assetName}
                    normalize={normalize}
                    onRangeUpdate={handleRangeUpdate}
                  />
                )}
              </Col>
            </Row>
            {!!addRun && (
              <Row className="advanced-asset-row">
                <Col>
                  {addTraces.length === 0 ? (
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                      <Icon
                        name="analytics"
                        size="6x"
                        className="mb-3"
                        variant="dark"
                        disabled={false}
                        showPointer={false}
                      />

                      <Text className="mb-4" as="h5">
                        There are currently no Readings for the selected Beacon/Ability/Time
                        combination.
                      </Text>
                    </div>
                  ) : (
                    <PlotlyChart
                      key={addRunChartKey}
                      traces={addTraces}
                      title="Run Comparison"
                      normalize={normalize}
                      onRangeUpdate={addRunHandleRangeUpdate}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
        <div className="advanced-asset-right-button-container">
          {/* <Icon
            name="run-shoes"
            size="2x"
            className="chart-icon"
            variant=""
            disabled={false}
            showPointer={false}
            />

            <Icon
            name="s-pulse"
            size="2x"
            className="chart-icon"
            variant=""
            disabled={false}
            showPointer={false}
            /> */}

          {/* Descriptive Statistics Button */}
          <Icon
            name="b-chart"
            traces={traces.length}
            advancedAsset={true}
            size="2x"
            className="chart-icon"
            variant=""
            disabled={false}
            showPointer={false}
            onClick={toggleCards}
          />
        </div>
      </div>

      {/* Descriptive Date */}
      {showCards && selectedBeacons.length !== 0 && traces.length !== 0 && (
        <div className="date-headers mt-4 mb-4 ">
          <h6 className="mb-0">
            <b>
              {moment(graphStartDate).format('MM/DD/YYYY h:mm A')} - {formattedGraphEndDate}
            </b>
          </h6>
        </div>
      )}

      {/* Descriptive Statistics Cards */}
      {showCards && selectedBeacons.length !== 0 && traces.length !== 0 && (
        <div className="mt-4 mb-4 row flex-row flex-nowrap statistics-card-wrapper">
          {selectedBeacons.map((selectedBeacon, index) => {
            const matchingTraces = traces.filter((trace) => {
              const traceName = trace.name.split(' - ')[0];
              return traceName === selectedBeacon.name;
            });
            if (matchingTraces.length > 0) {
              let lineColor = matchingTraces[0].line.color.replace('#', '');
              return (
                <Card key={index} className="mb-3 statistic-card">
                  <Card.Body>
                    <Card.Title className={`text-center color-${lineColor}`}>
                      {selectedBeacon.name}
                    </Card.Title>
                    <div className="d-flex">
                      {matchingTraces.map((trace, traceIndex) => {
                        const isLastRow = traceIndex === matchingTraces.length - 1;
                        const traceValue = trace.name.split(' - ')[1];
                        const truncatedTraceValue =
                          traceValue.length >= 20 ? `${traceValue.slice(0, 15)}...` : traceValue;
                        return (
                          <table key={traceIndex}>
                            <thead className="text-center">
                              <tr>
                                <th className={`color-${lineColor}`} colSpan={2}>
                                  {truncatedTraceValue}
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              className={`${isLastRow ? 'no-border-right' : 'solid-border-right'}`}
                            >
                              {readingLabels.map((label) => {
                                const isFirstRow = label === readingLabels[0];
                                return (
                                  <tr>
                                    <td
                                      className={`${
                                        isFirstRow ? 'add-padd' : 'no-padd'
                                      } text-end ability-label`}
                                    >
                                      <b>{label}:</b>
                                    </td>
                                    <td
                                      className={`${
                                        isFirstRow ? 'add-padd' : 'no-padd'
                                      } ability-stats`}
                                    >
                                      <b>{calculateStats(label, trace.y)}</b>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        );
                      })}
                    </div>
                  </Card.Body>
                </Card>
              );
            }
            return null;
          })}
        </div>
      )}

      {/* Descriptive Date */}
      {!!addRun && showCards && (
        <div className="date-headers mt-4 mb-4 ">
          <h6 className="mb-0">
            <b>
              {formattedAddRunStartDate} - {formattedAddRunEndDate}
            </b>
          </h6>
        </div>
      )}

      {/* Descriptive Statistics Cards For Add Run */}
      {showCards && selectedBeacons.length !== 0 && addTraces.length !== 0 && !!addRun && (
        <div className="mt-4 mb-4 row flex-row flex-nowrap statistics-card-wrapper">
          {selectedBeacons.map((selectedBeacon, index) => {
            const matchingTraces = addTraces.filter((trace) => {
              const traceName = trace.name.split(' - ')[0];
              return traceName === selectedBeacon.name;
            });
            if (matchingTraces.length > 0) {
              let lineColor = matchingTraces[0].line.color.replace('#', '');
              return (
                <Card key={index} className="mb-3">
                  <Card.Body>
                    <Card.Title className={`text-center color-${lineColor}`}>
                      {selectedBeacon.name}
                    </Card.Title>
                    <div className="d-flex">
                      {matchingTraces.map((trace, traceIndex) => {
                        const isLastRow = traceIndex === matchingTraces.length - 1;
                        const traceValue = trace.name.split(' - ')[1];
                        return (
                          <table key={traceIndex}>
                            <thead className="text-center">
                              <tr>
                                <th className={`color-${lineColor}`} colSpan={2}>
                                  {traceValue}
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              className={`${isLastRow ? 'no-border-right' : 'solid-border-right'}`}
                            >
                              {readingLabels.map((label) => {
                                const isFirstRow = label === readingLabels[0];
                                return (
                                  <tr>
                                    <td
                                      className={`${
                                        isFirstRow ? 'add-padd' : 'no-padd'
                                      } text-end ability-label`}
                                    >
                                      <b>{label}:</b>
                                    </td>
                                    <td
                                      className={`${
                                        isFirstRow ? 'add-padd' : 'no-padd'
                                      } ability-stats`}
                                    >
                                      <b>{calculateStats(label, trace.y)}</b>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        );
                      })}
                    </div>
                  </Card.Body>
                </Card>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default AdvancedCharting;
