export const assetCSVFormat = (dataResponse) => {
  for (let i = 0; i < dataResponse.length; i++) {
    let kv = '';
    let ids = [];

    dataResponse[i].identifiers.forEach((obj) =>
      obj.key != '' && obj.value != '' ? (kv += '(' + obj.key + '+' + obj.value + ')') : '',
    );

    dataResponse[i].beacons.forEach((obj) => ids.push(obj.deviceId));

    dataResponse[i] = {
      ids: dataResponse[i].id,
      name: dataResponse[i].name,
      keywords: dataResponse[i].keywords.join('+'),
      identifiers: kv,
      beaconIds: ids.join('+'),
      movementThreshold: dataResponse[i].movementThreshold,
      created: dataResponse[i].created,
      lastCheckIn: dataResponse[i].lastCheckin,
      beacons: dataResponse[i].beacons,
      location: dataResponse[i].location,
      gpsLocation: dataResponse[i].gpsLocation,
      scans: dataResponse[i].scans,
      status: dataResponse[i].status,
      favorite: dataResponse[i].favorite,
      locationBeaconId: dataResponse[i].locationBeaconId,
      preSignedImageUrl: dataResponse[i].preSignedImageUrl,
    };
  }
  return dataResponse;
};
