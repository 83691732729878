import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

// Used in breadcrumbs page & analytics report > Trigger res report

const DateTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            color: 'var(--dark)',
            top: '-7px',
          },
          '&.MuiInputLabel-shrink': {
            top: '0px',
          },
          '&.MuiInputLabel-shrink.Mui-focused': {
            color: 'var(--primary)',
          },
          '&.MuiInputLabel-shrink.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--c-primary)',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--danger)',
          },
        },
        notchedOutline: {
          borderColor: '#dee2e6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            color: 'var(--dark)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root:focus.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#0048FF',
          },
          '&.MuiIconButton-root': {
            color: 'var(--primary)',
          },
          '&.MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewIcon': {
            color: 'black',
          },
        },
      },
    },
  },
});

const DateTimeWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  justify-items: center;
  margin-top: 3px;
`;

const StartEndDatePicker = (props) => {
  const [hasDateRangeError, setHasDateRangeError] = useState(false);
  const [startDateErrorMsg, setStartDateErrorMsg] = useState('');
  const [endDateErrorMsg, setEndDateErrorMsg] = useState('');

  const clearDate = null;
  let startDiff;
  let endDiff;
  let dateDifference;

  const startDate = props.startDate ? moment(props.startDate, 'YYYY-MM-DD hh:mm A').toDate() : null;
  const endDate = props.endDate ? moment(props.endDate, 'YYYY-MM-DD hh:mm A').toDate() : null;

  if (startDate) startDiff = moment(startDate);
  if (endDate) endDiff = moment(endDate);
  if (startDate && endDate)
    dateDifference = Math.abs(moment.duration(startDiff.diff(endDiff)).asDays());

  useEffect(() => {
    const now = moment(); // Current date and time
    const start = moment(startDate);
    const end = moment(endDate);
    const dateDifference = end.diff(start, 'days');

    if (start.isAfter(end)) {
      // Start date is after the end date
      setHasDateRangeError(true);
      setStartDateErrorMsg('Start date must be before End date.');
      setEndDateErrorMsg('');
    } else if (start.isAfter(now) || end.isAfter(now)) {
      // Start date or end date is in the future
      setHasDateRangeError(true);
      setStartDateErrorMsg(start.isAfter(now) ? 'Start Date cannot be in the future.' : '');
      setEndDateErrorMsg(end.isAfter(now) ? 'End Date cannot be in the future.' : '');
    } else if (
      (props.reportType === 'gateway-network-uptime' && dateDifference > 60) ||
      (props.reportType === 'trigger-resolution' && dateDifference > 7)
    ) {
      setHasDateRangeError(true);
      setStartDateErrorMsg('');
      setEndDateErrorMsg(
        props.reportType === 'gateway-network-uptime'
          ? 'Date Range: 60 Days or Less'
          : 'Date Range: 1 Week or Less',
      );
    } else {
      // Valid date range, clear errors
      setHasDateRangeError(false);
      setStartDateErrorMsg('');
      setEndDateErrorMsg('');
    }
  }, [startDate, endDate, props.reportType, props.isBreadCrumbDates]);

  return (
    <div className="flex-row align-items-center flex-wrap">
      <Form>
        <DateTimeWrapper>
          <ThemeProvider theme={DateTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
              <DesktopDateTimePicker
                label="Start Date"
                maxDate={endDate ? new Date(endDate) : null}
                value={startDate || clearDate}
                onChange={(date) => {
                  props.onChangeStartDate(date);
                }}
                disableFuture
                className="me-3"
                slotProps={{
                  textField: {
                    error: hasDateRangeError,
                    helperText: startDateErrorMsg,
                  },
                }}
              />
              <DesktopDateTimePicker
                label="End Date"
                minDate={startDate ? new Date(startDate) : null}
                value={endDate || clearDate}
                onChange={(date) => {
                  props.onChangeEndDate(date);
                }}
                disableFuture
                slotProps={{
                  textField: {
                    error: hasDateRangeError,
                    helperText: endDateErrorMsg,
                  },
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </DateTimeWrapper>
      </Form>
    </div>
  );
};

export default StartEndDatePicker;
