import './AssetScreen.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  BeaconEditAbilitiesPopUp,
  BeaconNameAssignedDateRenderer,
  BeaconReadingsCellRenderer,
  CopyIconCellRenderer,
  IconCellRenderer,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
  PlacesCellRenderer,
  PrimaryBeaconRenderer,
  toast,
} from '../../components';
import { Api } from '../../services';
import { getFormattedDateTime, getValueOrDefault, isReadOnly, isUser } from '../../utils';
import { emitter, EVENTS } from './utils';

const MySwal = withReactContent(Swal);

export const assetColumnDefs = [
  {
    headerName: '',
    field: 'status',
    cellRenderer: OnlineStatusCellRenderer,
    width: 30,
    minWidth: 30,
    maxWidth: 30,
  },
  {
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/assets/${data.id}`,
      text: data.name || '--',
    }),
  },
  {
    headerName: 'Location',
    field: 'location.places',
    colId: 'places',
    cellRenderer: PlacesCellRenderer,
  },
  {
    headerName: 'Last Check-In',
    field: 'lastCheckin',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Readings',
    field: 'beacons',
    colId: 'readings',
    cellRenderer: BeaconReadingsCellRenderer,
  },
];

export const assocBeaconsColumnDefs = [
  {
    headerName: 'Unassign',
    colId: 'unassignBeacon',
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    cellClass: `d-flex flex-row justify-content-center`,
    cellRenderer: IconCellRenderer,
    pinned: 'left',
    cellRendererParams: (params) => {
      const { data, api, node } = params;
      return {
        className: 'sonarIcon-rotate-45 align-sub',
        iconName: 'add',
        hideIcon: isReadOnly() ? true : false,
        disabled: false,
        variant: 'light',
        size: 'md',
        onClick: async () => {
          try {
            let userResponsePromise = new Promise((resolve, reject) => {
              MySwal.fire({
                allowOutsideClick: false,
                title: 'Unassign Beacon from this Asset?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
              })
                .then((submit) => {
                  if (submit.isConfirmed) {
                    resolve(submit.isConfirmed);
                    api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(
                      node.rowIndex,
                      1,
                    );
                    api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
                    toast.success(`Successfully disassociated Beacon ${data.name}!`);
                  }
                })
                .catch((err) => {
                  reject(err);
                });
            });

            let userResponse;

            try {
              userResponse = await userResponsePromise;
            } catch (error) {
              userResponse = false;
              console.log(error);
            }

            if (userResponse) {
              await Api.delete(`/assets/${data.assetId}/beacons/${data.id}`);
              emitter.emit(EVENTS.REFETCH);
            }
          } catch (error) {
            toast.error('Failed to unassign Beacon from Asset.');
          }
        },
      };
    },
  },
  {
    headerName: 'Edit',
    colId: 'editBeacon',
    width: 48,
    minWidth: 48,
    maxWidth: 48,
    cellClass: 'd-flex flex-row justify-content-center',
    cellRenderer: BeaconEditAbilitiesPopUp,
    pinned: 'left',
    cellRendererParams: ({ data }) => {
      return {
        data,
        className: 'align-sub',
        iconName: 'edit',
        disabled: false,
        variant: 'light',
        size: 'md',
        style: { color: 'var(--primary)' },
      };
    },
  },
  {
    headerName: 'Name',
    field: 'name',
    minWidth: 300,
    cellStyle: { lineHeight: '20px' },
    valueGetter(params) {
      const { data } = params;
      return data;
    },
    cellRenderer: BeaconNameAssignedDateRenderer,
    pinned: 'left',
  },
  {
    headerName: 'Primary Beacon',
    field: 'primaryBeacon',
    cellClass: 'd-flex flex-row justify-content-center',
    width: 180,
    valueGetter(params) {
      const { data } = params;
      return data;
    },
    cellRenderer: PrimaryBeaconRenderer,
    pinned: 'left',
    comparator: (a, b) => {
      // Sort primary beacons to the top
      if (a.isLocationBeacon && !b.isLocationBeacon) return -1;
      if (!a.isLocationBeacon && b.isLocationBeacon) return 1;
      return 0; // Otherwise, do not change order
    },
    // Set initial sort state to have primary beacons at the top
    sort: 'asc',
    sortingOrder: ['asc', 'desc'],
  },
  {
    headerName: 'Device ID',
    field: 'deviceId',
    cellRenderer: CopyIconCellRenderer,
  },
  {
    headerName: 'Device ID Ending',
    field: 'deviceId',
    valueFormatter: (params) => {
      const { value } = params;
      let deviceIdEnd = '';
      if (value.includes(':')) {
        deviceIdEnd = value.substr(value.length - 8);
      } else {
        deviceIdEnd = value.substr(value.length - 6);
      }
      return deviceIdEnd;
    },
  },
];

export const triggersColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellStyle(params) {
      if (params.data.status === 'met') {
        return {
          color: 'red',
        };
      }
    },
  },
  {
    headerName: 'Description',
    field: 'description',
    valueFormatter: ({ value }) => getValueOrDefault(value),
    wrapText: true,
  },
];

export const scansColumnDefs = [
  {
    headerName: 'Date',
    field: 'created',
    valueFormatter: ({ value }) => getFormattedDateTime(value),
  },
  {
    headerName: 'Location',
    field: 'value',
  },
  {
    headerName: 'Scanned By',
    field: 'name',
    valueFormatter: ({ value }) => getValueOrDefault(value),
  },
];
