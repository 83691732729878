import './editMap.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { OnlineStatusCellRenderer } from '../../../../components';
import { getFormattedLocation } from '../../../../utils';
import GatewayEditRow from './gatewayEditRow';

class GatewayList extends Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm w-100">
          <button
            className="btn btn-primary btn-sm ms-4 me-4 mt-2 mb-2"
            type="button"
            onClick={() => {
              this.props.onAdd(), this.setState({ isNewGateway: true });
            }}
            disabled={this.props.editing}
          >
            Add Gateway
          </button>
        </div>

        <ul className="list-group list-group-flush edit-map-device-list">
          {/* Add Gateway Process / Editing Existing Gateways */}
          {this.props.gateways.map((g, i) => {
            return (
              <>
                {this.props.editing &&
                  g.index === this.props.editing.index &&
                  g.gateway == null &&
                  this.state?.isNewGateway && (
                    <GatewayEditRow
                      gateway={g}
                      excludedSearchItems={[]}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
                {this.props.editing &&
                  g.index === this.props.editing.index &&
                  g.gateway !== null &&
                  !this.state?.isNewGateway && (
                    <GatewayEditRow
                      gateway={g}
                      excludedSearchItems={[]}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
              </>
            );
          })}

          {/* List of Gateways / Hides when Adding New Gateway or Editing Gateways */}
          {!this.props.editing && (
            <>
              {Boolean(this.props.gateways.length) && (
                <>
                  <li className="d-flex list-group-item p-1 justify-content-between align-items-center ps-4 pe-4">
                    <div className="w-50">Gateway</div>
                    <div className="w-25">
                      <span className="float-right">Coordinates</span>
                    </div>
                    <div className="w-25">
                      <span className="float-right">Floor</span>
                    </div>
                    <div className="w-25"></div>
                  </li>
                </>
              )}
              {this.props.gateways.map((g, i) => {
                return (
                  <>
                    {g.gateway?.name && g.location && (
                      <li
                        className="list-group-item list-group-item-action ps-4 pe-4 pt-1 pb-1"
                        key={i}
                      >
                        <div className="w-100 d-flex align-items-center justify-content-between">
                          <span
                            className="w-50"
                            onClick={() => this.props.onSelect('gateways', 'index', g.index)}
                          >
                            {
                              <OnlineStatusCellRenderer
                                value={Boolean(g.gateway?.isOnline) ? 1 : 0}
                              />
                            }{' '}
                            {g.isVisible ? (
                              <strong>{g.gateway?.name}</strong>
                            ) : (
                              <span>{g.gateway?.name}</span>
                            )}
                          </span>

                          <div className="w-25">
                            {g.location ? (
                              <span className="float-right">
                                {getFormattedLocation(g.location)}
                              </span>
                            ) : (
                              false
                            )}
                          </div>
                          <div className="w-25">
                            <span className="float-right">{g.floor}</span>
                          </div>
                          <div className="w-25">
                            <div className="btn-group float-right edit-gateway-btns">
                              <span
                                className="btn flat-btn btn-default p-2 edit-properties-btn"
                                onClick={() => {
                                  this.props.onEdit(g), this.setState({ isNewGateway: false });
                                }}
                                title="Edit Properties"
                              >
                                <i className="fas fa-edit" />
                              </span>
                              <span
                                className="btn flat-btn btn-default p-2"
                                onClick={() => this.props.onEditMap(g)}
                                title="Change Gateway Location"
                              >
                                <i className="fas fa-map-pin" />
                              </span>
                              <span
                                onClick={() => this.props.onRemove('gateways', i)}
                                className="btn flat-btn btn-default p-2"
                                title="Remove from Map"
                              >
                                <i className="fas fa-times text-danger" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
            </>
          )}
        </ul>
      </div>
    );
  }
}

GatewayList.propTypes = {
  editing: PropTypes.object,
  gateways: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
  isDirty: PropTypes.any,
};

export default GatewayList;
